<template>
  <div>
    <h3 class="my-4">Feedback</h3>

  <!-- <v-data-table
    single-expand
    :headers="headers"
    :items="feedback"
    :expanded.sync="expanded"
    item-key="name"
    show-expand
    class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Expandable Table</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch
          v-model="singleExpand"
          label="Single expand"
          class="mt-2"
        ></v-switch>
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        More info about {{ item.name }}
      </td>
    </template>
  </v-data-table> -->
  <div style="height:calc(100vh - 138px); overflow: auto">

  <v-data-table
    :headers="feedbackHeaders"
    :items="feedback"
    :single-expand="true"
    :expanded.sync="expanded"
    :items-per-page="200"
    item-key="id"
    show-expand
    @item-expanded="rowExpanded"
    class="elevation-1">

    <template v-slot:top>
      <v-toolbar
        flat
      >
        <!-- <v-toolbar-title>My CRUD</v-toolbar-title> -->
        <!-- <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h6">Update Feedback (id: {{ editedItem.feedback_id }})</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-radio-group
                    class="mb-0 py-0"
                      v-model="editedItem.priority"
                      row>
                    <template v-slot:label>
                      <div><strong>Priority</strong>:</div>
                    </template>
                      <v-radio
                        label="High"
                        value="high"
                      ></v-radio>
                      <v-radio
                        label="Medium"
                        value="medium"
                      ></v-radio>
                      <v-radio
                        label="Low"
                        value="low"
                      ></v-radio>
                    </v-radio-group>
                    <v-radio-group
                    class="mt-0 py-0"
                      v-model="editedItem.status"
                      row>
                    <template v-slot:label>
                      <div><strong>Status</strong>:</div>
                    </template>
                      <v-radio
                        label="Open"
                        value="open"
                      ></v-radio>
                      <v-radio
                        label="Closed"
                        value="closed"
                      ></v-radio>
                    </v-radio-group>
                    <!-- <strong classA="mt-4">Logs</strong> -->
                    <v-textarea
                      v-model="editedItem.reply"
                      outlined
                      class="my-3"
                      rows="3"
                      label="Add reply">
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-text class="my-0 py-0">
              <v-alert
                v-if="ajaxStatus.length"
                :type="ajaxStatus">{{ ajaxMsg }}</v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                :disabled="buttonsDisabled"
                @click="closeEditedItem">
                Cancel
              </v-btn>
              <v-btn
                text
                :disabled="buttonsDisabled"
                @click="saveEditedItem">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px" min-height="200px">
          <v-card>
            <v-card-title class="text-h6">Are you sure you want to delete this item?<br>(id: {{ editedItem.feedback_id }})</v-card-title>
            <v-card-text class="my-0 py-0">
              <v-alert
                v-if="ajaxStatus.length"
                :type="ajaxStatus">{{ ajaxMsg }}</v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="buttonsDisabled" text @click="closeDelete">Cancel</v-btn>
              <v-btn :disabled="buttonsDisabled" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-container>
          <v-row>
            <v-col cols="12" md="1" align="right"></v-col>
            <v-col cols="12" md="5">
              <div v-if="item.feedback_type === 'stop-name'">
                Suggested new stop name change for stopid <strong>{{ item.stop_id }}</strong><br>
                <div class="suggested-changes">
                  <strong>name:</strong> {{ item.stop_name }}
                </div>
              </div>
              <div v-else-if="item.feedback_type === 'stop-location'">
                Suggested new stop location for stopid <strong>{{ item.stop_id }}</strong><br>
                <div class="suggested-changes">
                  <strong>latitude:</strong> {{ item.stop_lat }}<br>
                  <strong>longitude:</strong> {{ item.stop_lng }}
                </div>
              </div>
              <div v-else-if="item.feedback_type === 'missing-stop'">
                Suggested new stop location located after stopid <strong>{{ item.stop_id }}</strong> on route <strong>{{ item.route_id }}</strong><br>
                <div class="suggested-changes">
                  <strong>name:</strong> {{ item.stop_name }}<br>
                  <strong>latitude:</strong> {{ item.stop_lat }}<br>
                  <strong>longitude:</strong> {{ item.stop_lng }}
                </div>
              </div>
              <div v-else-if="item.feedback_type === 'redundant-stop'">
                Suggested redundant stop <br>
                <div class="suggested-changes">
                  <strong>stop id:</strong> {{ item.stop_id }}
                </div>
              </div>
              <div v-else-if="item.feedback_type === 'incorrect-fare'">
                Suggested fare change for stopid <strong> {{ item.route_id }}</strong> <br>
                <div class="suggested-changes">
                  <strong>Full fare:</strong> {{ item.comments.split(",")[0] }} <br>
                  <strong>Leg fare:</strong> {{ item.comments.split(",")[1] }}
                </div>
              </div>
                <div
                  v-if="item.comments.length"
                  class="feedback-comments">
                  <div><strong>Comments</strong></div>
                  {{ item.feedback_type === 'incorrect-fare' ? item.comments.split(',').slice(2).join(',') : item.comments }}
                </div>
            </v-col>
            <v-col cols="12" md="5">
              <h5>Log trail</h5>
              <div
                v-for="(log, index) in logs"
                :key="'log-' + index"
                class="log-reply">
                {{ log.reply || '-' }}
                <div class="log-reply-changes">
                  {{ log.changes}}
                </div>
                <div class="log-reply-notes">
                  <span v-if="log.author">by {{ log.author }}</span>
                  <span>{{ log.log_date }}</span>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="1" align="right"></v-col>
            <v-col cols="12" md="10">
              <span style="margin-right:10px; font-style:italic">
              {{ item.browser_name }} {{ item.browser_major }} ({{ item.os_name }})
              </span>
              <v-btn
                link
                outlined
                small
                color="primary"
                @click="openURL(item.app_url)">URL <v-icon small>mdi-open-in-new</v-icon> </v-btn>
              <!-- <pre>{{ item }}</pre> -->
            </v-col>
            <v-col cols="12" md="2">
            </v-col>
          </v-row>
        </v-container>
      </td>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>

  </v-data-table>

  </div>

    <!-- <pre>{{ feedback }}</pre> -->
  </div>
</template>

<script>
  import { fetchFeedback, updateFeedback, deleteFeedback, fetchFeedbackLog } from '@/api/admin'

  export default {
    data() {
      return {
        ajaxStatus: '',
        dialog: false,
        dialogDelete: false,
        editedItemDefault: {
          priority: 'low',
          status: 'open',
          reply: '',
          feedback_id: ''
        },
        editedItem: {
          priority: 'low',
          status: 'open',
          reply: '',
          feedback_id: ''
        },
        expanded: [],
        feedback: [],
        logs: [],
        feedbackHeaders: [
          { text: 'ID', value: 'id', align: 'start', },
          { text: 'Feedback Type', value: 'feedback_type' },
          { text: 'Status', value: 'status' },
          { text: 'Priority', value: 'priority' },
          { text: 'Date', value: 'datestamp' },
          { text: 'Actions', value: 'actions', sortable: false },
          { text: '', value: 'data-table-expand' },
          // { text: 'Feedback Type', value: 'feedback_type' },
          // { text: 'Feedback Type', value: 'feedback_type' },
          // { text: 'Feedback Type', value: 'feedback_type' },
          // { text: 'Feedback Type', value: 'feedback_type' },
        ],
        buttonsDisabled: false,
        headers: [
          { value: 'app_url', text: 'app_url' },
          { value: 'browser_major', text: 'browser_major' },
          { value: 'browser_name', text: 'browser_name' },
          { value: 'comments', text: 'comments' },
          { value: 'datestamp', text: 'datestamp' },
          { value: 'device_model', text: 'device_model' },
          { value: 'device_type', text: 'device_type' },
          { value: 'device_vendor', text: 'device_vendor' },
          { value: 'feedback_type', text: 'feedback_type' },
          { value: 'id', text: 'id' },
          { value: 'os_major', text: 'os_major' },
          { value: 'os_name', text: 'os_name' },
          { value: 'route_id', text: 'route_id' },
          { value: 'stop_id', text: 'stop_id' },
          { value: 'stop_lat', text: 'stop_lat' },
          { value: 'stop_lng', text: 'stop_lng' },
          { value: 'stop_name', text: 'stop_name' },
          { value: 'user_agent', text: 'user_agent' }
        ]
      }
    },
    methods: {
      openURL (url) {
        window.open(url)
      },
      rowExpanded ({ item, value }) {
        console.log("rowExpanded -> item", item, value)
        if (value) {
          this.fetchLogs(item.id)
        }
      },

      editItem (item) {
        console.log("editItem -> item", item)
        // this.editedIndex = this.desserts.indexOf(item)
        let { id:feedback_id, status, priority } = item
        this.editedItem = Object.assign({}, this.editedItem, { feedback_id, status, priority })
        this.dialog = true
      },

      deleteItem (item) {
        console.log("editItem -> item", item)
        let { id:feedback_id } = item
        this.editedItem = { feedback_id }
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        this.buttonsDisabled = true
        this.ajaxStatus = ''
        this.ajaxMsg = ''
        try {
          await deleteFeedback(this.editedItem)
          this.ajaxStatus = 'success'
          this.ajaxMsg = 'Successfully deleted feedback!'
          this.fetchFeedback()
        } catch (error) {
          this.ajaxStatus = 'error'
          this.ajaxMsg = 'Failed to delete feedback!'
        }
        this.buttonsDisabled = false
        setTimeout(() => {
          this.dialog = false
          this.ajaxMsg = ''
          this.ajaxStatus = ''
          this.closeDelete()
        }, 2000)
      },
      async saveEditedItem () {
        this.buttonsDisabled = true
        this.ajaxStatus = ''
        this.ajaxMsg = ''
        try {
          await updateFeedback(this.editedItem)
          this.ajaxStatus = 'success'
          this.ajaxMsg = 'Successfully added feedback update!'
          this.fetchFeedback()
        } catch (error) {
          this.ajaxStatus = 'error'
          this.ajaxMsg = 'Failed to update feedback!'
        }
        this.buttonsDisabled = false
        setTimeout(() => {
          this.dialog = false
          this.ajaxMsg = ''
          this.ajaxStatus = ''
        }, 2000)
      },
      closeEditedItem () {
        this.editedItem = { ...this.editedItemDefault }
        this.dialog = false
        // this.$nextTick(() => {
        //   this.editedItem = Object.assign({}, this.defaultItem)
        //   this.editedIndex = -1
        // })
      },

      closeDelete () {
        this.dialogDelete = false
        // this.$nextTick(() => {
        //   this.editedItem = Object.assign({}, this.defaultItem)
        //   this.editedIndex = -1
        // })
      },
      async fetchFeedback () {
        const response = await fetchFeedback()
        console.log("mounted -> response", response)
        this.feedback = response.feedback
      },
      async fetchLogs (feedback_id) {
        this.logs = []
        const response = await fetchFeedbackLog({ feedback_id })
        console.log("mounted -> response", response)
        this.logs = response.logs
      }
    },
    mounted () {
      this.fetchFeedback()
    },
  }
</script>

<style lang="scss" scoped>
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 8px -5px rgb(50 50 50 / 75%), inset 0px -4px 8px -5px rgb(50 50 50 / 75%);
  background-color: red;
}
.suggested-changes {
  border: solid 1px #ddd;
  background-color:  rgba(0, 128, 0, 0.2);
  border-radius: 5px;
  padding: 7px;
  margin-top: 5px;
  display: block;
  min-width: 260px;
}
.feedback-comments {
  border: solid 1px #ddd;
  background-color:  rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 7px;
  margin-top: 5px;
  display: block;
  min-height: 80px;
}
.log-reply {
  padding: 5px;
  background-color: rgba(0,0,0,0.1);
  border-radius: 5px;
  margin-top: 6px;
}
.log-reply-notes {
  font-size: 11px;
}
.log-reply-changes {
  font-size: 11px;
  font-style: italic;
}
</style>